import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
    esignAction, HeaderInfoAction,
    actionCreators as uploadedDocumentStatusAction,
} from "../../../redux/actions";
import { useParams } from "react-router-dom";
import { IOrganizerMetadata, IHeaderInfoViewModel, FormGroupStatus, ISignerDocumentModel, IPreparerMessage } from "../../../models";
import { WelcomePopupModal } from "./welcomePopupModal";
import { images } from "../../../assets/index";
import { OrganizerConstants,EngagementLetterConstants, SourceDocumentConstants } from "../../../common/constants/constant";
import { container, TYPES } from "../../../core/startup";
import { ILocalStore } from "../../../core/utilities";
import { Shimmer, MessageBox } from "../../common";
import { UploadedDocumentStoreState } from "redux/reducers/uploadedDocumentStatus/model";

const LocalStorageService = container.get<ILocalStore>(TYPES.ILocalStore);

export const Welcome: React.FC<{}> = () => {
    const { signIcon, downloadDocumentIcon, uploadIcon, preparerMessageIcon } = images;

    const history = useHistory();
    const dispatch = useDispatch();
    const params: any = useParams();

    const [engagementLetterStatus, setEngagementLetterStatus] = useState<FormGroupStatus>(FormGroupStatus.None);
    const [engagementSignerStatus, setEngagementSignerStatus] = useState<FormGroupStatus>(FormGroupStatus.None);
    const [organizerStatus, setOrganizerStatus] = useState(false);
    const [show, setShow] = useState(false);
    const [enabledSourceDocuments, setEnabledSourceDocuments] = useState(false);
    const [isEngagementcontrol, setIsEngagementcontrol] = useState(true);
    const [hasAdditionalEDocuments, setHasAdditionalEDocuments] = useState(true);
    
    const [signingOrder, setSigningOrder] = useState(1);

    const { organizerMetadata, headerInfoMetaData, preparerMessage } = useSelector(
        (state: { organizerMetadata: IOrganizerMetadata; headerInfoMetaData: IHeaderInfoViewModel, preparerMessage: IPreparerMessage }) => state
    );

    const {
        isCompleted, loading
    } = useSelector((state: { uploadedDocumentStatusReducer: UploadedDocumentStoreState }) => state.uploadedDocumentStatusReducer);

    let loadHeaderTitle = () => {
        dispatch(HeaderInfoAction.setHeaderText(organizerMetadata.senderName));
    };

    useEffect(() => {
        if (history.location.state) {
            const state = history.location.state as { fromOTPPage: boolean };
            state.fromOTPPage ? setShow(true) : setShow(false);
        } else {
            setShow(false);
        }
    }, [history.location.state]);

    useEffect(() => {
        
        dispatch(esignAction.getOrganizerMetadata(params.id));
        dispatch(esignAction.getPreparerMessage(params.id));
        dispatch(uploadedDocumentStatusAction.requestUploadedDocumentStatus(params.id));
        dispatch(
            esignAction.getDocumentStatusAndSourceFileSettings(params.id, (response: any) => {
                setEnabledSourceDocuments(response.data.enabledSourceDocuments);
                LocalStorageService.setItemByUniqueKey(
                    params.id,
                    SourceDocumentConstants.SourceDocumentEnabled,
                    response.data.enabledSourceDocuments
                );
                setOrganizerStatus(response.data.organizerStatus);
                setEngagementLetterStatus(response.data.engagementLetterStatus);
                setEngagementSignerStatus(response.data?.engagementSignerStatus)
                setIsEngagementcontrol(response.data.isEngagementcontrol);
                setSigningOrder(response.data.client.signingOrder);
            })
        );
    }, [dispatch]);

    useEffect(() => {
        loadHeaderTitle();
    }, [organizerMetadata]);

    const handleClose = () => setShow(false);

    let navigateToDownloadDocument = () => {
        replaceHistoryState();
        history.push(OrganizerConstants.ViewandDownloadEngagementLetterPageURL + params.id);
    };

    let navigateToSignEngagement = () => {
        replaceHistoryState();
        history.push(OrganizerConstants.EngagementLetterSignPageURL + params.id);
    };

    let naviagteToUpload = () => {
        replaceHistoryState();
        history.push(OrganizerConstants.UploadDocumentPageURL + params.id);
    };

    let naviagteToPreparerMessage = () => {
        replaceHistoryState();
        history.push(OrganizerConstants.PreparerMessageURL + params.id);
    };

    let replaceHistoryState = () => {
        if (history.location.state) {
            history.replace({ state: { fromOTPPage: false } });
        }
    };

    let handleRedirect = () => {
        if (engagementLetterStatus !== FormGroupStatus.None && engagementLetterStatus !== FormGroupStatus.ESigned) {
            navigateToSignEngagement();
        } else {
            naviagteToUpload();
        }
    };

    const getPreparerMessageButton = () => {
        return (
            <div className="row engagementLetterButton" onClick={naviagteToPreparerMessage} data-test-auto="C4DE3CC9-981F-4133-BDC9-85E18FBB5751">
                <div className="col-2">
                    <img src={preparerMessageIcon.src} className="margin-5" alt={preparerMessageIcon.alt} />
                </div>
                <div className="col-10" style={{ alignSelf: "center" }}>
                    <span className="welcome-action-text">Message from Tax Preparer</span>
                </div>
            </div>
        )
    };

    let getDownloadEnagementButton = () => {
        return (
            <div className="row engagementLetterButton" onClick={navigateToDownloadDocument} data-test-auto="C4DE3CC9-981F-4133-BDC9-85E18FBB5751">
                <div className="col-2">
                    <img src={downloadDocumentIcon.src} className="margin-5" alt={downloadDocumentIcon.alt} />
                </div>
                <div className="col-10" style={{ alignSelf: "center" }}>
                    <span className="welcome-action-text">View & Download Signed Documents </span>
                </div>
            </div>
        );
    };

    let getSignEngagementButton = () => {
        let isEsignEnable: boolean = signingOrder == 2 && engagementLetterStatus == FormGroupStatus.AwaitingESign;
        return (
            <div
                onClick={navigateToSignEngagement}
                className={
                    isEsignEnable ? "disabledEngagementButton row engagementLetterButton" : "row engagementLetterButton"
                }
                data-test-auto="E8DB7613-47D3-4800-B524-F1E55B9B63A6"
            >
                <div className="col-2">
                    <img src={signIcon.src} className="margin-5" alt={signIcon.alt} />
                </div>
                <div className="col-10" style={{ alignSelf: "center" }}>
                    <span className="welcome-action-text">Sign Documents</span>
                </div>
            </div>
        );
    };

    const getCompletedUploadDocumentsButton = () =>
    (<div className="row engagementLetterButton" onClick={naviagteToUpload} data-test-auto="B04E1B0D-A243-4BCB-8807-3BC4AAFC240B">
        <div className="col-2">
            <img src={downloadDocumentIcon.src} className="margin-5" alt={downloadDocumentIcon.alt} />
        </div>
        <div className="col-10" style={{ alignSelf: "center" }}>
            <span className="welcome-action-text"> View & Download <br/>Documents </span>
        </div>
    </div>);


    const getUploadDocumentsButton = () => {
        let isDisableable: boolean = (engagementSignerStatus === FormGroupStatus.NA && (engagementLetterStatus !== FormGroupStatus.None && engagementLetterStatus !== FormGroupStatus.NA)) 
            && !(signingOrder > 1 && !isEngagementcontrol)
            && !(engagementLetterStatus === FormGroupStatus.ESigned || engagementLetterStatus === FormGroupStatus.ManuallySigned);

        return (
            <div
                className={`row engagementLetterButton ${isDisableable ? 'area-disabled' : ''}`}
                onClick={naviagteToUpload}
                data-test-auto="B04E1B0D-A243-4BCB-8807-3BC4AAFC240A"
            >
                <div className="col-2">
                    <img src={uploadIcon.src} className="margin-5" alt={uploadIcon.alt} />
                </div>
                <div className="col-10" style={{ alignSelf: "center" }}>
                    <span className="welcome-action-text"> Upload Documents </span>
                </div>
            </div>
        );
    };

    return (
        <>
            <WelcomePopupModal
                taxYear={organizerMetadata.taxYear}
                clientName={organizerMetadata.clientName}
                show={show}
                handleClose={handleClose}
            />

            <div data-testid="welcomePage" className="welcome-client-page container">
                <div className="welcome-client-page-container row">
                    <div className="col-12 welcomepage-clientDetails" style={{ textAlign: "center" }}>
                        <div>
                            <br />
                            <b>
                                Welcome{" "}
                                {organizerMetadata ? organizerMetadata.clientName : <Shimmer height={30} width={100} />}
                            </b>
                            <br />
                            <b>{organizerMetadata.senderName}</b>
                            <br />
                            has sent you some documents that need to be filled out and signed
                            <br />
                            <br />
                        </div>
                        {((signingOrder > 1 && !isEngagementcontrol) ||
                            [FormGroupStatus.NA ,FormGroupStatus.None].includes(engagementLetterStatus)) &&
                            !enabledSourceDocuments ? (
                            <div className="row" style={{ marginTop: "15%" }}>
                                <div className="col-12">
                                    <b>
                                        To complete your organizer and view, print, download or forward your tax
                                        documents please login using a computer or tablet
                                    </b>
                                </div>
                            </div>
                        ) : (
                            <p>Please select an item below to be completed</p>
                        )}
                        {
                            preparerMessage.message && getPreparerMessageButton()
                        }
                        {
                            organizerMetadata ? (
                                <>
                                    {
                                    ((signingOrder > 1 && !isEngagementcontrol) || engagementLetterStatus == FormGroupStatus.NA) ? "" :
                                        engagementLetterStatus != FormGroupStatus.None &&
                                        ((engagementLetterStatus == FormGroupStatus.ESigned
                                            || engagementLetterStatus == FormGroupStatus.ManuallySigned || engagementSignerStatus == FormGroupStatus.Reviewed)
                                            ? getDownloadEnagementButton()
                                            : getSignEngagementButton())
                                    }
                                    {
                                        !loading ?
                                            enabledSourceDocuments && (
                                                isCompleted ?
                                                    getCompletedUploadDocumentsButton() :
                                                    getUploadDocumentsButton()
                                            )
                                            : <Shimmer height={20} width={100} />
                                    }
                                </>
                            ) : (
                                <Shimmer height={20} width={100} />
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
