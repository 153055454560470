import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Toaster } from "../common";
import {
  HeaderInfoAction,
  organizerAction,
  esignAction,
} from "../../redux/actions";
import { Header } from "../../components/layout/header";
import { ConfirmModal } from "../common";
import { MyAccount } from "./myAccount";
import { useHistory } from "react-router";
import { OrganizerConstants } from "../../common/constants/constant";
import { LocalStorageConstant, SourceDocumentConstants } from "../../common";
import { ILocalStore, IUtilities } from "../../core/utilities";
import { container, TYPES } from "../../core/startup";
import { ChangeMobileNumber } from "./changeMobileNumber";
import { IBaseHeaderInfoViewModel, IClientInfo } from "../../models";
import { MessageBox } from "../../components/common/notification/notification";
import { ValidationContants } from "../../common";
import SessionTimeout from "../../components/layout/timeout/sessionTimeout";
declare global {
  interface Window {
      Variables: any;
      pendo: any;
  }
}
interface IPendoPayload{
  name: string;
  email: string;
  companyId: number;
  companyName: string;
  userId:string;
  isTestCompany: boolean;
  companySubscription: string;
}
const LocalStorageService = container.get<ILocalStore>(TYPES.ILocalStore);
const Utilities = container.get<IUtilities>(TYPES.IUtilities);

export function DefaultLayout(props: any) {
  const dispatch = useDispatch();
  const params: any = useParams();
  const history = useHistory();
  let renewIntervalId = useRef<NodeJS.Timeout | null>(null);

  const warningVisibleMinutes = 1;
  const [showMyAccountPopUp, setShowMyAccountPopUp] = useState(false);
  const [showChangeNumberPopUp, setshowChangeNumberPopUp] = useState(false);
  const [showLogoutPopUp, setshowLogoutPopUp] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [sessionIdleMinutes, setSessionIdleMinute] = useState(0);
  const [showTimeout, setShowTimeout] = useState(false);
  const { baseHeaderInfoMetaData, documentClientsMetaData, clientInfo } =
    useSelector(
      (state: {
        baseHeaderInfoMetaData: IBaseHeaderInfoViewModel;
        documentClientsMetaData: IClientInfo;
        clientInfo: IClientInfo;
      }) => state
    );
  
  useEffect(() => {
    dispatch(
      organizerAction.getSessionTimeOutMinutes(
        params.id,
        (sessionExpiryMinutes: number) => {
          const interval = sessionExpiryMinutes * 1000 - 300000; //Renew session before 5 min from token expiry minutes

          renewIntervalId.current = setInterval(() => {
            renewSession();
          }, interval);

          setSessionIdleMinute(sessionExpiryMinutes / 60);
        }
      )
    );

    return () => {
      console.log(`clearing renewal interval`);
      clearInterval(renewIntervalId.current ?? -1);
    };
  }, []);

  useEffect(() => {
    dispatch(HeaderInfoAction.requestHeaderInfo(params.id));
    dispatch(HeaderInfoAction.getProfileData(params.id));
    dispatch(organizerAction.getClientInfo(params.id));
    dispatch(esignAction.getSpouseInfo(params.id));
  }, [dispatch]);

  useEffect(()=>{
    if(baseHeaderInfoMetaData.profileData.companyId&&clientInfo.name){
      const payload={
        name:clientInfo.name,
        email:clientInfo.email,
        companyId:baseHeaderInfoMetaData.profileData.companyId,
        companyName:baseHeaderInfoMetaData.profileData.companyName,
        userId:baseHeaderInfoMetaData.profileData.userId,
        isTestCompany:baseHeaderInfoMetaData.profileData.isTestCompany,
        companySubscription:baseHeaderInfoMetaData.profileData.companySubscription
      }
      injectPendoScript(payload)
    }
  },[baseHeaderInfoMetaData.profileData,clientInfo]);

  var injectPendoScript = (data:IPendoPayload) => {
    const {
      companyId,
      companyName,
      userId,
      email,
      name,
      isTestCompany,
      companySubscription
    } = data;
   
    if (!companyId || !companyName || !userId || !name || !email) {
      console.log("User profile not available yet");
      return;
    }

    const apiKey = process.env.REACT_APP_PENDO_API_KEY;

    (function (p: any, e: any, n: any, d: any, o: any) {
      var v: any, w: any, x: any, y: any, z: any;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo", "");

    window.pendo?.initialize({
      visitor: {
        id: `${companyId}-${userId}-${email}`,
        email: email,
        name:name
      },
      account: {
        Id: companyId,
        AccountName: companyName,
        FirmType: isTestCompany ? 'Internal' : 'Live',
        Subscription: companySubscription
      }
    });
  };
  let getContactPersonFullName = (
    firstName: string,
    middleName: string,
    lastName: string
  ) => {
    let contactPersonFullName: string = "";
    contactPersonFullName += firstName;
    if (middleName && middleName.length > 0) {
      contactPersonFullName += " " + middleName;
    }
    if (lastName && lastName.length > 0) {
      contactPersonFullName += " " + lastName;
    }
    return contactPersonFullName;
  };

  let getMyaccount = () => {
    setShowMyAccountPopUp(true);
  };

  let openLogout = () => {
    setshowLogoutPopUp(true);
  };

  let closeLogout = () => {
    setshowLogoutPopUp(false);
  };

  let closeMyAccount = () => {
    setShowMyAccountPopUp(false);
  };

  let logoutUser = () => {
    const clientid = LocalStorageService.getItemByUniqueKey(
      params.id,
      LocalStorageConstant.EncryptedClientId
    );
    dispatch(
      HeaderInfoAction.updateClientLogout(params.id, () => {
        setLogOut();
      })
    );
  };

  let onChangeMobileNumberClick = () => {
    setshowChangeNumberPopUp(true);
  };

  let onHideChangeNumberPopUp = () => {
    setshowChangeNumberPopUp(false);
  };

  let onSaveNumberClick = (
    changedMobileNumber: string,
    changedCountryCode: string
  ) => {
    if (
      (changedMobileNumber === "" && changedCountryCode === "") ||
      (Utilities.validatePhone(changedMobileNumber) &&
        Utilities.validateCountryCode(changedCountryCode))
    ) {
      let taxPayer: IClientInfo = documentClientsMetaData;
      taxPayer.mobileNumber = changedMobileNumber;
      taxPayer.countryCode = changedCountryCode;
      dispatch(organizerAction.updateClientMobileNumber(params.id, taxPayer));
      setshowChangeNumberPopUp(false);
      setMobileNumber(changedMobileNumber);
      setCountryCode(changedCountryCode);
    }
  };

  let getMobileNumber = (): string => {
    return documentClientsMetaData.mobileNumber === null
      ? ""
      : documentClientsMetaData.mobileNumber;
  };

  let getCountryCode = (): string => {
    return documentClientsMetaData.countryCode === null
      ? ""
      : documentClientsMetaData.countryCode;
  };

  let updateSpouseDetails = (
    editedEmail: string,
    editedMobileNumber: string,
    editedCountryCode: string
  ) => {
    const isValidPhone: boolean =
      editedMobileNumber !== ""
        ? Utilities.validatePhone(editedMobileNumber)
        : true;
    const isValidCountryCode: boolean =
      editedCountryCode !== ""
        ? Utilities.validateCountryCode(editedCountryCode)
        : true;
    if (
      editedEmail.trim() === "" ||
      !Utilities.isValidEmailAddress(editedEmail)
    ) {
      MessageBox.Warning(ValidationContants.EmailAddressWarning);
    } else if (
      (editedMobileNumber.trim() === "" && editedCountryCode.trim() !== "") ||
      (editedMobileNumber.trim() !== "" && editedCountryCode.trim() === "")
    ) {
      Utilities.validatePhone(editedMobileNumber);
      Utilities.validateCountryCode(editedCountryCode);
    } else if (isValidPhone && isValidCountryCode) {
      let spouse = clientInfo;
      spouse.email = editedEmail;
      spouse.mobileNumber = editedMobileNumber;
      spouse.countryCode = editedCountryCode;
      dispatch(esignAction.updateSpouseEmail(params.id, spouse));
      dispatch(organizerAction.updateClientMobileNumber(params.id, spouse));
      setCountryCode(editedCountryCode);
      setEmailAddress(editedEmail);
      setMobileNumber(editedMobileNumber);
      setshowChangeNumberPopUp(false);
      setShowMyAccountPopUp(false);
    }
  };

  //==============Session TimeOut Handling Start=================

  let onIdle = (e: any) => {
    setShowTimeout(true);
  };

  let onStayAlive = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    renewSession();
    setShowTimeout(false);
  };

  let renewSession = () => {
    const refreshToken = LocalStorageService.getItemByUniqueKey(
      params.id,
      LocalStorageConstant.RefreshToken
    );

    dispatch(
      organizerAction.getRefreshToken(
        params.id,
        refreshToken,
        (result: any) => {
          if (result && result.isSuccess === true) {
            setToken(
              result.data.clientId.toString(),
              result.data.token,
              result.data.refreshToken
            );
          } else {
            clearInterval(renewIntervalId.current ?? -1);
          }
        }
      )
    );
  };

  let setToken = (clientId: string, token: string, refreshToken: string) => {
    LocalStorageService.setItemByUniqueKey(
      clientId,
      LocalStorageConstant.Token,
      token
    );
    LocalStorageService.setItemByUniqueKey(
      clientId,
      LocalStorageConstant.RefreshToken,
      refreshToken
    );
  };

  let setLogOut = () => {
    clearInterval(renewIntervalId.current ?? -1);
    const clientid = LocalStorageService.getItemByUniqueKey(
      params.id,
      LocalStorageConstant.EncryptedClientId
    );
    LocalStorageService.removeItemByUniqueKey(
      params.id,
      LocalStorageConstant.Token
    );
    LocalStorageService.removeItemByUniqueKey(
      params.id,
      LocalStorageConstant.RefreshToken
    );
    LocalStorageService.removeItemByUniqueKey(
      params.id,
      LocalStorageConstant.EncryptedClientId
    );
    LocalStorageService.removeItemByUniqueKey(
      params.id,
      SourceDocumentConstants.SourceDocumentEnabled
    );
    history.push(OrganizerConstants.CoverPageURL + clientid);
  };

  //==============Session TimeOut Handling End=================

  const contactInfoPopover = () => {
    return (
      <Popover
        id="contactInfoPopover"
        placement="right"
        className="popover-contact"
      >
        <h3 className="popover-header">Contact Information</h3>
        <div
          className="popover-body"
          data-test-auto="3464EE21-1DF8-4F1F-BE4B-D838ACE36298"
        >
          <div className="medium">
            {getContactPersonFullName(
              baseHeaderInfoMetaData.contactPerson.firstName,
              baseHeaderInfoMetaData.contactPerson.middleName,
              baseHeaderInfoMetaData.contactPerson.lastName
            )}
          </div>
          <div className="mail">
            <a
              href={
                "mailto:" + baseHeaderInfoMetaData.contactPerson.emailAddress
              }
            >
              {baseHeaderInfoMetaData.contactPerson.emailAddress}
            </a>
          </div>
          <div>
            <strong className="strong-phone">
              {Utilities.formateFax(baseHeaderInfoMetaData.contactPerson.phone)}
            </strong>
            {baseHeaderInfoMetaData.contactPerson &&
              baseHeaderInfoMetaData.contactPerson.extension &&
              baseHeaderInfoMetaData.contactPerson.extension.length > 0 && (
                <strong className="strong-phone">
                  Ext: {baseHeaderInfoMetaData.contactPerson.extension}{" "}
                </strong>
              )}
          </div>
        </div>
      </Popover>
    );
  };

  return (
    <>
      <div data-testid="defaultLayout" className="row header-container">
        <div className={"header-nav row"}>
          <div
            className="header-left-container"
            data-test-auto="951602DF-76D9-480A-BA0F-D12E216FBB2B"
          >
            <input
              type="checkbox"
              className="openSidebarMenu"
              id="openSidebarMenu"
            />
            <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
              <div className="spinner diagonal part-1"></div>
              <div className="spinner horizontal"></div>
              <div className="spinner diagonal part-2"></div>
            </label>
            <div id="sidebarMenu">
              <ul className="sidebar navbar-nav ul-slidebar">
                <li className="li-nav-first">
                  <h4 title={baseHeaderInfoMetaData.clientName}>
                    {baseHeaderInfoMetaData.clientName}
                  </h4>
                </li>

                <li
                  data-test-auto="9E3149F1-20E9-48E8-9067-5E70360C410F"
                  className="li-nav-second"
                >
                  <span
                    className="span-nav"
                    title="Contact Person's Information"
                  >
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      rootClose={true}
                      placement="right"
                      overlay={contactInfoPopover}
                      data-test-auto="D78B6331-F513-4D2E-8A2F-56A4379262C4"
                    >
                      <button className={"contact-info-button-overlay"}>
                        Contact Person
                      </button>
                    </OverlayTrigger>
                  </span>
                </li>

                <li className="li-nav">
                  <span className="span-nav">
                    <a
                      onClick={getMyaccount}
                      data-test-auto="65AD7EA3-7B05-43C9-B862-F079DE711606"
                    >
                      My Account
                    </a>
                  </span>
                </li>

                <li className="li-nav">
                  <span className="span-nav" onClick={openLogout}>
                    Logout
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <Header />
        </div>
      </div>

      <ConfirmModal
        show={showLogoutPopUp}
        handleNo={closeLogout}
        handleYes={logoutUser}
        message={"Are you sure you want to logout ?"}
      ></ConfirmModal>

      <ChangeMobileNumber
        showState={showChangeNumberPopUp}
        onSaveNumberClick={onSaveNumberClick}
        onHideChangeNumberPopUp={onHideChangeNumberPopUp}
        mobileNumber={getMobileNumber()}
        countryCode={getCountryCode()}
      ></ChangeMobileNumber>

      <MyAccount
        show={showMyAccountPopUp}
        closeMyAccount={closeMyAccount}
        onChangeNumberClick={onChangeMobileNumberClick}
        taxPayer={documentClientsMetaData}
        spouse={!clientInfo.isDeceased ? clientInfo : undefined}
        onSaveButtonClick={updateSpouseDetails}
      ></MyAccount>

      {sessionIdleMinutes > 0 && (
        <IdleTimer
          element={document}
          onIdle={onIdle}
          debounce={250}
          timeout={1000 * 60 * (sessionIdleMinutes - warningVisibleMinutes)}
        />
      )}

      <SessionTimeout
        showModal={showTimeout}
        onStayAlive={onStayAlive}
        countDownMinutes={warningVisibleMinutes}
        onLogOut={setLogOut}
      />
      <Toaster />
      <div className="body-container">{props.children}</div>
    </>
  );
}
